var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coupon"},[_c('div',{staticClass:"coupon--header"},[(_vm.coupon.redeem_code_bundle && _vm.coupon.redeem_code_bundle.name)?_c('div',{staticClass:"s-px-3 s-text-2xl s-font-bold text-truncate"},[_vm._v(" "+_vm._s(_vm.coupon.redeem_code_bundle.name)+" ")]):_vm._e(),_c('div',{staticClass:"s-mt-2"},[_c('p',[_vm._v("使用期限：")]),_c('time',[_vm._v(" "+_vm._s(_vm.startToEnd))])])]),_c('div',{staticClass:"coupon--wrapper"},[_c('div',{staticClass:"coupon--image"},[(_vm.coupon.redeem_code_bundle && _vm.coupon.redeem_code_bundle.img_url)?_c('img',{attrs:{"src":_vm.coupon.redeem_code_bundle.img_url}}):_vm._e()]),_c('div',{staticClass:"coupon--body"},[(!_vm.isNotYet && !_vm.isExpired)?_c('div',[(
          _vm.coupon.redeem_code_bundle &&
          _vm.coupon.redeem_code_bundle.is_show_barcode
        )?_c('div',{staticClass:"coupon--barcode s-mb-4"},[(_vm.showBarcodeError)?_c('p',{staticClass:"s-text-danger"},[_vm._v(" barcode 產生錯誤，請聯絡相關人員 ")]):_c('img',{attrs:{"id":_vm.barcodeImgId,"src":require("@/pages/Dashboard/Coupon/barcode.png")}})]):_vm._e(),(_vm.coupon.code)?_c('div',{staticClass:"coupon--copy-panel"},[_c('span',{staticClass:"coupon--barcode"},[_vm._v(" "+_vm._s(_vm.coupon.code)+" ")]),_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.coupon.code),expression:"coupon.code",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.clipboardSuccessHandler),expression:"clipboardSuccessHandler",arg:"success"}],staticClass:"coupon--copy-button",attrs:{"id":"copy-tooltip-target"}},[_c('i',{class:_vm.copyIconClass,attrs:{"aria-hidden":"true"}})]),_c('b-tooltip',{ref:"copyTooltip",attrs:{"target":"copy-tooltip-target","placement":"top"}},[_vm._v(" 已複製 ")])],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"coupon--footer"},[_c('div',{staticClass:"coupon--line mb-2"}),(_vm.showConfirmButton)?_c('div',{staticClass:"s-mb-4 s-mt-3"},[_c('div',{staticClass:"coupon-confirm mb-1"},[_vm._v("確認使用後將無法復原，請您確認。")]),_c('button',{staticClass:"s-btn s-btn-bg-primary s-btn-xs",attrs:{"type":"button","disabled":!!_vm.coupon.redeem_at || _vm.isNotYet || _vm.isExpired},on:{"click":_vm.handleConfirm}},[_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")])]):_vm._e(),(
        _vm.coupon.redeem_code_bundle && _vm.coupon.redeem_code_bundle.is_show_button
      )?_c('div',{staticClass:"s-mb-4 s-mt-3"},[_c('button',{staticClass:"s-btn s-btn-outline-primary s-btn-xs d-block text-truncate",attrs:{"type":"button"},on:{"click":_vm.handleUse}},[_vm._v(" "+_vm._s(_vm.coupon.redeem_code_bundle && _vm.coupon.redeem_code_bundle.button_text)+" ")])]):_vm._e(),(
        _vm.coupon.redeem_code_bundle && _vm.coupon.redeem_code_bundle.description
      )?_c('div',{staticClass:"s-mb-4 s-px-3 coupon--desc",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.coupon.redeem_code_bundle.description)+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }