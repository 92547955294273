<template>
  <div></div>
</template>

<script>
import couponApi from '@/apis/liff/v2/coupon'
  import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      lineOaId: 'liffGeneral/liffOaId',
    }),
  },
  mounted() {
    this.autoApply()
  },
  methods: {
    async autoApply() {
      try {
        let code = await this.doAutoApply()

        console.log('code', code)
        this.$swal.fire({ title: "領取成功", type: "success" })
          .then(() => this.goToCouponPage(code.id))
      } catch (error) {
        console.log("error", error)
        if ([406, 410].includes(error.response.status)) {
          this.$swal.fire({ title: error.response.data.message, type: "error" })
            .then(() => {error.response.status == 406 ? this.goToCouponPage(error.response.data.id) : this.goToLineOa()})
        } else {
          this.$swal.fire({ title: "領取失敗", type: "error" })
            .then(() => this.goToLineOa())
          }
      }
    },
    async doAutoApply() {
      return await couponApi.applyCouponFromRedeemCodeBundle({ id: this.$route.params.id }, {
        push_line_message: true,
        check_take_type_direct: false,
      })
    },
    goToLineOa() {
      window.location.href = `https://line.me/R/ti/p/@${this.lineOaId}`
    },
    goToCouponPage(id) {
      this.$router.push({ name: 'LiffCouponShow', params: {id: id} })
    }
  }
}
</script>
