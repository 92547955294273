<template>
  <div class="page">
    <div class="page__title">
     {{ getModuleConfig("records_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('records_page.form_desc')"
    ></div>
    <div  class="page__content" v-if="getModuleConfig('records_page.content')" v-html="getModuleConfig('apply_page.content')"></div>
    <coupon-filters
      v-if="showFilter"
      class="s-mb-3"
      :options="filterOptions"
      v-model="filter"
    ></coupon-filters>
    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading && filteredCoupons[filter].length > 0">
        <coupon-card
          v-for="item in filteredCoupons[filter]"
          :key="item.id"
          :coupon="item"
          :buttonText="item.status == 'applied_and_not_yet' ? '尚未開始' : '查看'"
          @exchange="showCouponModal"
        >
        </coupon-card>
        <Pagination v-model="page" :total-page="lastPage" />
      </div>
      <coupon-empty v-else />
    </div>
    <coupon-modal ref="couponModal" :coupon="selectedCoupon" @confirmed="refresh"/>
  </div>
</template>

<script>
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue";
import CouponModal from "@/components/Page/Liff/CouponList/CouponModal.vue"
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import CouponFilters from "@/pages/Dashboard/Coupon/CouponFilters.vue";
import couponApi from '@/apis/liff/v2/coupon'
import Loading from "@/components/Page/Liff/Shared/Loading";
import Pagination from '@/components/Page/Liff/Shared/Pagination'
import couponMixin from "@/mixins/liff/coupon";

export default {
  mixins: [couponMixin],
  components: { CouponCard, CouponEmpty, CouponFilters, Loading, CouponModal, Pagination },
  data() {
    return {
      showLoading: true,
      filter: "all",
      coupons: [],
      provider: null,
      selectedCoupon: {},
      page: +this.$route.query.page || 1,
      lastPage: 1,
    };
  },

  computed: {
    showFilter() {
      return this.provider == "waltily"
    },
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (" + this.coupons.length + ")"}`,
        },
        {
          key: "used",
          text: `${"已使用 (" + this.filteredCoupons.used.length + ")"}`,
        },
        {
          key: "expired",
          text: `${"已過期 (" + this.filteredCoupons.expired.length + ")"}`,
        },
      ];
    },

    filteredCoupons() {
      return {
        all: this.coupons,
        used: this.coupons.filter((c) => c.status === "redeemed"),
        expired: this.coupons.filter((c) => c.status === "expired"),
      };
    },
  },
  watch: {
    page () {
      this.fetchCoupon()
    },
  },

  mounted() {
    this.fetchCoupon();
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true
      try {
        const data = await couponApi.getCouponLists({
          page: this.page,
          status: 'record',
        });
        this.coupons = data.data
        this.provider = data.provider
        this.lastPage = data.lastPage
      } catch (error) {
        if (error.response.data.message) {
          this.$swal.fire({
            type: "error",
            title: "錯誤",
            text: error.response.data.message
          });
        }
      }
      this.showLoading = false
      this.coupons.forEach((coupon) => {
        coupon.is_enabled = coupon.status === 'applied_and_not_yet' ? false : true
      })
    },
    showCouponModal(coupon) {
      this.selectedCoupon = coupon
      this.$refs.couponModal.open()
    },
    refresh() {
      this.selectedCoupon = {}
      this.fetchCoupon()
    },
  },
};
</script>

<style></style>
