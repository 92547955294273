import https from "./https";
import store from "@/store";

const coupon = {
  async getRedeemCodeBundles(params) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/redeem-code-bundles`, { params })
    return {
      data: data.data,
      lastPage: data.meta.last_page
    }
  },

  async applyCouponFromRedeemCodeBundle(redeemCouponBundle, params = {}) {
    const { data } = await https.post(`${store.state.liffGeneral.orgCode}/liff/redeem-code-bundles/${redeemCouponBundle.id}/apply`, params)
    return data
  },

  async getCoupons(params) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/redeem-codes`, { params })
    return data.data
  },

  async applyCoupon(coupon) {
    await https.post(`${store.state.liffGeneral.orgCode}/liff/redeem-codes/${coupon.id}/apply`)
  },

  async takeCoupon(code) {
    await https.post(`${store.state.liffGeneral.orgCode}/liff/redeem-codes/take`, { code })
  },

  async redeemCoupon(coupon) {
    await https.post(`${store.state.liffGeneral.orgCode}/liff/redeem-codes/${coupon.id}/redeem`, coupon)
  },

  async getCoupon(id) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/redeem-codes/${id}`)
    return data.data
  },

  async myCodesInBundle(bundleId) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/redeem-codes/my-codes-in-bundle/${bundleId}`)
    return data.data
  },

  async getCouponLists(params) {
    const { data } = await https.get(`${store.state.liffGeneral.orgCode}/liff/coupon-list`, { params })
    return {
      data: data.data,
      lastPage: data.meta.last_page,
      provider: data.provider,
    }
  },
}

export default coupon
