<template>
  <div class="coupon">
    <div class="coupon--header">
      <div
        v-if="coupon.redeem_code_bundle && coupon.redeem_code_bundle.name"
        class="s-px-3 s-text-2xl s-font-bold text-truncate"
      >
        {{ coupon.redeem_code_bundle.name }}
      </div>
      <div class="s-mt-2">
        <p>使用期限：</p>
        <time> {{ startToEnd }}</time>
      </div>
    </div>

    <div class="coupon--wrapper">

    <div class="coupon--image">
      <img
        v-if="coupon.redeem_code_bundle && coupon.redeem_code_bundle.img_url"
        :src="coupon.redeem_code_bundle.img_url"
      />
    </div>
    <div class="coupon--body">
      <div v-if="!isNotYet && !isExpired">
        <div
          class="coupon--barcode s-mb-4"
          v-if="
            coupon.redeem_code_bundle &&
            coupon.redeem_code_bundle.is_show_barcode
          "
        >
          <p v-if="showBarcodeError" class="s-text-danger">
            barcode 產生錯誤，請聯絡相關人員
          </p>
          <img
            v-else
            :id="barcodeImgId"
            src="@/pages/Dashboard/Coupon/barcode.png"
          />
        </div>

        <div v-if="coupon.code" class="coupon--copy-panel">
          <span class="coupon--barcode">
            {{ coupon.code }}
          </span>

          <div
            id="copy-tooltip-target"
            class="coupon--copy-button"
            v-clipboard:copy="coupon.code"
            v-clipboard:success="clipboardSuccessHandler"
          >
            <i :class="copyIconClass" aria-hidden="true"></i>
          </div>

          <b-tooltip
            ref="copyTooltip"
            target="copy-tooltip-target"
            placement="top"
          >
            已複製
          </b-tooltip>
        </div>
      </div>
    </div>

    <div class="coupon--footer">
      <div class="coupon--line mb-2"></div>
      <div class="s-mb-4 s-mt-3" v-if="showConfirmButton">
        <div class="coupon-confirm mb-1">確認使用後將無法復原，請您確認。</div>
        <button
          class="s-btn s-btn-bg-primary s-btn-xs"
          type="button"
          @click="handleConfirm"
          :disabled="!!coupon.redeem_at || isNotYet || isExpired"
        >
          {{ confirmButtonText }}
        </button>
      </div>
      <div
        class="s-mb-4 s-mt-3"
        v-if="
          coupon.redeem_code_bundle && coupon.redeem_code_bundle.is_show_button
        "
      >
        <button
          class="s-btn s-btn-outline-primary s-btn-xs d-block text-truncate"
          type="button"
          @click="handleUse"
        >
          {{
            coupon.redeem_code_bundle && coupon.redeem_code_bundle.button_text
          }}
        </button>
      </div>
      <div
        v-if="
          coupon.redeem_code_bundle && coupon.redeem_code_bundle.description
        "
        class="s-mb-4 s-px-3 coupon--desc"
        style="white-space: pre-line"
      >
        {{ coupon.redeem_code_bundle.description }}
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import bwipjs from "bwip-js";
import { format, isAfter, isBefore } from "date-fns";

import couponApi from "@/apis/liff/v2/coupon";
import * as consts from "@/consts";
Vue.use(VueClipboard);

export default {
  props: {
    coupon: {
      type: Object,
      default: () => {
        return {
          code: null,
          redeem_code_bundle: {},
        };
      },
    },
  },

  data() {
    return {
      canvasElement: null,
      showBarcodeError: false,
      copyIconClass: "fa fa-copy",
    };
  },

  computed: {
    barcodeImgId() {
      return `coupon-barcode-${this.coupon.id}`;
    },
    showConfirmButton() {
      return (
        this.coupon.redeem_code_bundle?.confirm_type !==
        consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.NONE
      );
    },
    confirmButtonText() {
      if (this.coupon.redeem_at) {
        return "已使用";
      } else if (this.isNotYet) {
        return "尚未開始";
      } else if (this.isExpired) {
        return "已過期";
      } else {
        return "確認使用";
      }
    },
    isNotYet() {
      if (!this.coupon.start_at) return false;
      // 票卷結束時間在今天之前 => 已經結束
      return isBefore(new Date(), new Date(this.coupon.start_at));
    },
    isExpired() {
      if (!this.coupon.end_at) return false;

      // 票卷開始時間在今天之後 => 尚未開始
      return isAfter(new Date(), new Date(this.coupon.end_at));
    },
    startToEnd() {
      let startAt = this.coupon?.start_at ?? false;
      let endAt = this.coupon?.end_at ?? false;

      if (!startAt && !endAt) return "";

      let startAtText = startAt
        ? format(new Date(startAt), "yyyy/MM/dd HH:mm")
        : "無期限";
      let endAtText = endAt
        ? format(new Date(endAt), "yyyy/MM/dd HH:mm")
        : "無期限";

      return `${startAtText} ~ ${endAtText}`;
    },
  },

  mounted() {
    this.generateBarcode();
  },

  methods: {
    format,
    generateBarcode() {
      if (!this.canvasElement) {
        this.canvasElement = document.createElement("canvas");
      }

      try {
        bwipjs.toCanvas(this.canvasElement, {
          bcid: this.getBarcdoeType(), // Barcode type
          text: this.coupon.code,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });

        let barcodeDom = document.getElementById(this.barcodeImgId);

        if (!barcodeDom || !this.barcodeImgId) {
          this.showBarcodeError = true;
          return;
        }

        barcodeDom.src = this.canvasElement.toDataURL("image/png");
        this.showBarcodeError = false;
      } catch (e) {
        console.log(e);
        this.showBarcodeError = true;
      }
    },
    getBarcdoeType() {
      switch (this.coupon.redeem_code_bundle?.barcode_type) {
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_128:
          return "code128";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_39:
          return "code39";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.CODE_39_WITH_CHECKSUM:
          return "code39ext";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.PZN:
          return "pzn";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.EAN_13:
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.JAN: // another name for the EAN-13
          return "ean13";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.EAN_8:
          return "ean8";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISBN_13:
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISBN_10:
          return "isbn";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.ISSN:
          return "issn";
        case consts.REDEEM_CODE_BUNDLE_BARCODE_TYPES.UPC_A:
          return "upca";
      }
    },
    handleConfirm() {
      this.showConfrimModal();
    },
    handleUse() {
      if (this.coupon.redeem_code_bundle?.button_url) {
        const buttonUrl = this.coupon.redeem_code_bundle?.button_url.replace(
          "{$code}",
          this.coupon.code
        );

        if (this.coupon.redeem_code_bundle?.button_url_external) {
          window.open(buttonUrl, "_blank").focus();
        } else {
          window.location.href = buttonUrl;
        }
      }
    },
    showConfrimModal() {
      let confirmInput =
        this.coupon?.redeem_code_bundle?.confirm_type ===
        consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.BRANCH_CODE
          ? {
              input: "text",
              inputPlaceholder: "請輸入店號",
              animation: false,
            }
          : {};
      this.$swal
        .fire({
          title: "確定要使用嗎？",
          text: "確認使用後將無法復原",
          confirmButtonText: "確認使用",
          cancelButtonText: "取消",
          showCancelButton: true,
          reverseButtons: true,
          showLoaderOnConfirm: true,
          ...confirmInput,
          preConfirm: async (result) => {
            this.coupon.branch_code = result;
            this.redeemCoupon();
            if (
              this.coupon?.redeem_code_bundle?.confirm_type ===
              consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.BRANCH_CODE
            ) {
              return false;
            }
          },
        })
        .then((result) => {
          if (!result.value) {
            return;
          }
        });
    },
    async redeemCoupon() {
      try {
        await couponApi.redeemCoupon(this.coupon);
        this.$swal.fire({ title: "完成使用", type: "success" });
        this.$emit("confirmed");
      } catch (error) {
        console.log("error", error);
        if (
          this.coupon?.redeem_code_bundle?.confirm_type ===
          consts.REDEEM_CODE_BUNDLE_CONFIRM_TYPES.BRANCH_CODE
        ) {
          this.$swal.showValidationMessage("使用失敗");
        } else {
          this.$swal.fire({ title: "使用失敗", type: "error" });
        }
      }
    },
    clipboardSuccessHandler() {
      this.$refs.copyTooltip.$emit("open");
      this.copyIconClass = "fa fa-check";
      setTimeout(() => {
        this.copyIconClass = "fa fa-copy";
        this.$refs.copyTooltip.$emit("close");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../node_modules/font-awesome/css/font-awesome.min.css";
.coupon {  
  display: flex;
  flex-direction: column;
  text-align: center;

  box-sizing: content-box;


  border-radius: 10px;
  background-size: 100% 100% ;
  background-repeat: no-repeat;
  background-image: radial-gradient(circle at 50% 101%, rgba(white,0) 1.5em, white (1.6em));
  background-position: top left, top right;
  filter: drop-shadow(0 2px 8px rgba(black,.3));
  padding-bottom: 1.5em;



  &--header {
    background: var(--liff-header-color);
    color: var(--liff-header-text-color);
    padding: 15px 10px 10px 10px;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-top: 1px solid var(--liff-header-text-color);
    border-left: 1px solid var(--liff-header-text-color);
    border-right: 1px solid var(--liff-header-text-color);
  }

  &--barcode {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  &--wrapper{
    overflow-y: scroll;
  }

  &--image {
    margin: 12px;

    img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
  }

  &--body {
    padding: 0 30px;
    margin: 0 12px 12px 12px;
  }

  &--footer {
    padding: 0 24px 12px;

    .coupon-confirm {
      color: #ea0000;
      font-size: 14px;
    }
  }

  &--copy-panel {
    background: #f2f2f7;
    border-radius: 10px;
    padding: 12px 8px;
    position: relative;
  }

  &--copy-button {
    background: rgba(102, 153, 204, 0.03);
    border: 1px solid rgba(102, 153, 204, 0.6);
    color: #3973ac;
    padding: 4px 16px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    position: absolute;
    right: 8px;
    top: 10px;
  }

  &--line {
    border: 1px dashed #dadada;
  }

  button {
    font-size: 16px;

    &.s-btn-bg-primary {
      background: var(--liff-button-color);
      color: var(--liff-button-text-color);
    }
  }
}
</style>
<style scoped>
.coupon--desc {
  text-align: left;
  font-size: 0.9rem;
  box-shadow: rgb(from var(--s-primary) r g b / 0.5) 0 2px 8px 0;
  border-radius: 5px;
  padding: 10px 15px;
}
</style>
