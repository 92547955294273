<template>
  <div>
    <div class="py-4">
      <Loading v-if="showLoading" loading-message="載入中..." />
      <coupon-card
        class="t-4"
        v-else-if="!showLoading && coupons.length > 0"
        v-for="item in filteredCoupons[filter]"
        :key="item.id"
        :coupon="item"
        :buttonText="item.status == 'applied_and_not_yet' ? '尚未開始' : '查看'"
        @exchange="showCouponModal"
      >
      </coupon-card>
      <coupon-empty v-else />
    </div>
    <coupon-modal ref="couponModal" :coupon="selectedCoupon" @confirmed="refresh"/>
  </div>
</template>

<script>
import CouponCard from "@/pages/Dashboard/Coupon/CouponCard.vue"
import CouponModal from "@/components/Page/Liff/Coupon/CouponModal.vue"
import couponApi from '@/apis/liff/v2/coupon'
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  components: {
    CouponCard,
    CouponModal,
    CouponEmpty,
    Loading
  },
  data() {
    return {
      bundleId: null,
      showLoading: true,
      filter: "all",
      coupons: [],
      selectedCoupon: {},
    }
  },

  computed: {
    filterOptions() {
      return [
        {
          key: "all",
          text: `${"全部 (" + this.coupons.length + ")"}`,
        },
        {
          key: "upcoming",
          text: `${"即將到期 (" + this.filteredCoupons.upcoming.length + ")"}`,
        },
        {
          key: "notYet",
          text: `${"尚未開始 (" + this.filteredCoupons.notYet.length + ")"}`,
        },
      ]
    },
    filteredCoupons() {
      return {
        all: this.coupons,
        upcoming: this.coupons.filter((c) => c.status === "upcoming"),
        notYet: this.coupons.filter((c) => c.status === "applied_and_not_yet"),
      }
    },
  },

  mounted() {
    this.bundleId = this.$route.params.id;
    this.fetchCoupon()
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true
      this.coupons = await couponApi.myCodesInBundle(this.bundleId)
      this.coupons.forEach((coupon) => {
        coupon.is_enabled = coupon.status === 'applied_and_not_yet' ? false : true
      })

      if (this.coupons.length == 1 && this.coupons[0].id) {
        this.$router.push({
          name: "LiffCouponShow",
          params: {
            id: this.coupons[0].id,
          }
        })

        return;
      }

      this.showLoading = false
    },
    showCouponModal(coupon) {
      this.selectedCoupon = coupon
      this.$refs.couponModal.open()
    },
    refresh() {
      this.selectedCoupon = {}
      this.fetchCoupon()
    },
  },
}
</script>

<style lang="scss" scoped></style>
