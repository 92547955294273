<template>
  <div class="coupon-show">
    <Loading v-if="showLoading" loading-message="載入中..." />
    <div v-else class="coupon-show__panel">
      <div class="coupon_close" @click="redirectToOa">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M368 368L144 144M368 144L144 368"/></svg>
      </div>

      <coupon
        v-if="coupon"
        ref="couponModal"
        :coupon="coupon"
        @confirmed="refresh"
      />
      <coupon-empty v-else />
    </div>
  </div>
</template>

<script>
import Coupon from "@/components/Page/Liff/Coupon/Coupon.vue";
import CouponEmpty from "@/pages/Dashboard/Coupon/CouponEmpty.vue";
import couponApi from "@/apis/liff/v2/coupon";
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  components: { Coupon, CouponEmpty, Loading },
  data() {
    return {
      showLoading: true,
      coupon: null,
    };
  },

  async mounted() {
    await this.fetchCoupon();
  },

  methods: {
    async fetchCoupon() {
      this.showLoading = true;
      try {
        this.coupon = await couponApi.getCoupon(this.$route.params.id);
      } catch (e) {
        console.error(e);
        this.coupon = null;
      }
      this.showLoading = false;
    },
    refresh() {
      this.fetchCoupon();
    },
    redirectToOa() {
      window.location.href = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.orgCode}/liff/general/redirect-to-oa`;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-show {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  background-color: var(--s-gray-lighter);
  display: flex;
  align-items: center;
  padding: 12px;
  z-index: 50;
  color: #636366;
  max-width: 600px;
  margin: auto;
}

.coupon-show__panel {
  position: relative;
  width: 100%;
  max-height: 100%;

  z-index: 10;
  display: flex;
  flex-direction: column;
  // background-image: url(./bg-coupon-bottom.png);
  // background-size: contain;
  // background-repeat: no-repeat;
  // background-position: bottom;
  //padding-bottom: 24px;


 

  .coupon {
    overflow-y: scroll;
  }
  .circle {
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ccc;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
  }

  .icon {
    font-size: 30px;
  }
}

.coupon_close {  
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background: var(--s-white);
  border-radius: 50%;  
  margin: 0 auto 10px auto;
}

// .coupon_close--icon:before,
// .coupon_close--icon:after {
//   content: "";
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 60%;
//   height: 2px;
//   background-color: var(--s-black);
// }

// .coupon_close--icon:before {
//   transform: translate(-50%, -50%) rotate(45deg);
// }

// .coupon_close--icon:after {
//   transform: translate(-50%, -50%) rotate(-45deg);
// }
</style>
