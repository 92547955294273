import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      meta: 'liffGeneral/meta',
      themeConfig: 'liffGeneral/themeConfig',
      getModule: 'liffModule/getModule',
    }),
    moduleConfig() {
      return this.getModule('liff_coupon').module_config || {}
    },
  },
}

