<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("select_item_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('select_item_page.form_desc')"
    ></div>
    <!-- <div  class="page__content" v-if="getModuleConfig('select_item_page.content')" v-html="getModuleConfig('select_item_page.content')"></div> -->

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div class="field">
          <!-- <label class="field__label"> 我的代理商 </label> -->
          <div class="row">
            <div
              class="col-12 col-sm-6 px-2"
              v-for="(option, idx) in options"
              :key="idx"
            >
              <SharedRadio
                v-model="proxy"
                :id="`proxy-${idx}`"
                :value="option.value"
                name="proxy"
              >
                {{ option.text }}
              </SharedRadio>
            </div>
          </div>
        </div>

        <small>{{ getModuleConfig("select_item_page.form_memo") }}</small>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('select_item_page.button_text') ||
        getModuleConfig('select_item_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <SharedButton
        @click="$router.push({ name: 'LiffCouponListSelectBranch' })"
        class="s-btn-bg-primary mb-2"
        >{{ getModuleConfig("select_item_page.button_text") }}</SharedButton
      >
      <SharedButton variant="outline-dark" class="s-btn-outline-primary">{{
        getModuleConfig("select_item_page.cancel_button_text")
      }}</SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import couponMixin from "@/mixins/liff/coupon";
import SharedRadio from "@/components/DynamicForm/Radio.vue";

export default {
  mixins: [couponMixin],
  components: { Loading, SharedButton, SharedRadio },
  data() {
    return {
      showLoading: false,
      options: [
        { text: "安素 原味不甜", value: "1" },
        { text: "安素 香草少甜", value: "2" },
        { text: "葡勝納 Select 嚴選", value: "3" },
        { text: "葡勝納 SR菁選原味不甜", value: "4" },
        { text: "倍力素", value: "5" },
      ],
      proxy: null,
    };
  },

  computed: {},
  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.field {
  &__label {
    font-size: 20px;
    margin-bottom: 8px;
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
  }

  &__desc {
    font-size: 16px !important;
  }
}

.row {
  margin: 0 -0.5rem;
}


</style>
