var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-wrapper"},[(_vm.headerSection.enable_page_title || _vm.headerSection.enable_menu_items)?_c('div',{staticClass:"s-header",style:({ order: _vm.contentsOrder.page_title })},[(_vm.headerSection.enable_menu_items)?_vm._l((_vm.headerSection.menu_items),function(i,idx){return _c('div',{key:idx,class:("s-header--" + (i.float) + " s-flex")},[(i.type == 'meta.header_image')?[(_vm.headerLogo)?_c('div',{staticClass:"s-header--logo"},[_c('img',{attrs:{"src":_vm.headerLogo}})]):_vm._e()]:[(i.type == 'go_back')?[(i.url == undefined)?_c('a',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('icon',{attrs:{"item":i}}),_vm._v(" "+_vm._s(i.title)+" ")],1):(_vm.moduleMap.map(function (x) { return x.type; }).includes(i.url))?_c('router-link',{attrs:{"to":{
              name: _vm.moduleMap.find(function (x) { return x.type == i.url; }).value,
              params: {
                org_code: _vm.$route.params.orgCode || _vm.$route.params.org_code,
                orgCode: _vm.$route.params.orgCode || _vm.$route.params.org_code,
              },
            }}},[_c('icon',{attrs:{"item":i}}),_vm._v(" "+_vm._s(i.title)+" ")],1):_c('a',{attrs:{"target":"_blank","href":i.url}},[_c('icon',{attrs:{"item":i}}),_vm._v(" "+_vm._s(i.title)+" ")],1)]:[(i.url != undefined)?_c('a',{attrs:{"target":"_blank","href":i.url}},[_c('icon',{attrs:{"item":i}}),_vm._v(" "+_vm._s(i.title)+" ")],1):_c('router-link',{attrs:{"to":_vm.moduleMap.map(function (x) { return x.type; }).includes(i.type)
                ? {
                  name: _vm.moduleMap.find(function (x) { return x.type == i.type; }).value,
                  params: {
                    org_code:
                      _vm.$route.params.orgCode || _vm.$route.params.org_code,
                    orgCode:
                      _vm.$route.params.orgCode || _vm.$route.params.org_code,
                  },
                }
                : '/'}},[_c('icon',{attrs:{"item":i}}),_vm._v(" "+_vm._s(i.title)+" ")],1)]]],2)}):_vm._e(),(_vm.headerSection.enable_page_title)?_c('div',{staticClass:"s-header__title"},[_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]):_vm._e()],2):_vm._e(),(_vm.headerSection.enable_page_image)?_c('div',{staticClass:"header-image",style:({
      order: _vm.contentsOrder.page_image,
      paddingBottom: _vm.pageImageStyle['padding-bottom'],
      paddingTop: _vm.pageImageStyle['padding-top'],
    })},[(_vm.headerImage)?[_c('img',{style:({width:_vm.pageImageStyle['width']}),attrs:{"src":_vm.headerImage}})]:_vm._e()],2):_vm._e(),(_vm.headerSection.enable_page_kv_image && _vm.pageKvImage)?_c('img',{staticClass:"header-kv-image w-100",style:({ order: _vm.contentsOrder.page_kv_image }),attrs:{"src":_vm.pageKvImage}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }