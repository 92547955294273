<template>
  <div class="s-tabs">
    <router-link
      class="s-tab"
      v-for="tab in tabs"
      :key="tab.order"
      :to="{ path: tab.path || tab.provider, query: tab.query || {} }"
      :class="{ 'active-tab': (currentTab && (currentTab === tab.id || currentTab ===tab.path)) }"
    >
      {{ tab.type_name }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
    },
    currentTab: {
      type: String,
    }
  }
};
</script>

<style lang="scss">
.s-tabs {
  display: flex;
  background-color: var(--s-white);
  position: sticky;
  top:0;
  z-index: 10;
  .s-tab {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid #e5e5ea;

    color: var(--liff-tab_inactive_color) !important;

  

    // &.router-link-exact-active, &.active {
    //   border-bottom: 2px solid var(--liff-tab-color);
    //   color: var(--liff-tab-color);
    // }
  }

  a.active-tab {
    border-bottom: 2px solid var(--liff-tab-color);
    color: var(--liff-tab-color) !important;
  }
}
</style>
