<template>
  <div class="dynamic-radio form-check">
    <input
      type="radio"
      :id="id"
      :name="name"
      :value="value"
      :checked="modelValue === value"
      @change="$emit('change', $event.target.value)"
      :disabled="disabled"
      class="form-check-input"
    />
    <label class="form-check-label " :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
    },
    value: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  model: {
    prop: "modelValue",
    event: "change",
  },
};
</script>

<style lang="scss" scoped>
.dynamic-radio {
  display: flex;
  align-items: center;
  padding: 0.5rem !important;
  border: dotted 1px var(--liff-button-color) !important;
  border-radius: 3px;
  margin-bottom: 10px;

  &:has(.form-check-input:checked){
    border-style: solid;
    background-color: var(--liff-button-color);
    color: white;
  }

  .form-check-input {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    -webkit-appearance: none;
    border-radius: 50%;
    outline: none;
    border: 3px solid var(--liff-button-color);
    opacity: .5;

    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    &:checked:before {
        background: white;
    }

    &:checked {
      border-color:white;
      opacity: 1;
    }
  }

  .form-check-label {
    padding-left: 25px;
    font-size: 18px;
    flex:1 1 auto;
    cursor: pointer;
  }
}


</style>
