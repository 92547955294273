<template>
  <div class="coupon-filters">
    <div
      :class="{ isActive: option.key === activeFilter }"
      class="coupon-filters--option"
      v-for="option in options"
      :key="option.key"
      @click="handleClick(option.key)"
    >
      {{ option.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    options: {
      type: Array,
    },
  },

  computed: {
    activeFilter: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },

  methods: {
    handleClick(val) {
      this.activeFilter = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.coupon-filters {
  display: flex;
}
.coupon-filters--option {
  border: 1px solid #63636699;
  padding: 7px 16px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #2c2c2e;
  border-radius: 50px;
  display: inline-flex;
  margin-right: 9px;
  &.isActive {
    background: var(--s-primary);
    border: 1px solid var(--s-primary);
    color: #ffffff;
  }
}
</style>
